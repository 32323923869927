@import url("./assets/fonts/stylesheet.css");

* {
    margin: 0;
    padding: 0;
}

html {
    overflow: hidden;
}

body {
    font-family: "DM Sans";
}

a {
    text-decoration: none;
}

.error-msg {
    font-size: .875em;
    color: #e25a6b;
}

/* Preloader */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    /*  background: #d91a31;*/
}

.preloader .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* Logo Animation */
    /* Circle Animation */
}

.preloader .loader #logo__animation {
    width: 146px;
    height: 230px;
}

.preloader .loader #logo__animation svg {
    -webkit-animation: logoup 2s ease-in-out infinite;
    animation: logoup 2s ease-in-out infinite;
}

.preloader .loader #logo__animation svg #bg__logo {
    fill: transparent;
    -webkit-animation: bglogofade 2s ease-in-out infinite;
    animation: bglogofade 2s ease-in-out infinite;
}

.preloader .loader #logo__animation svg #inner__logo {
    fill: #fff;
    -webkit-animation: innerlogofade 2s ease-in-out infinite;
    animation: innerlogofade 2s ease-in-out infinite;
}

.preloader .loader #circle-animation {
    width: 146px;
    text-align: center;
}

.preloader .loader #stroke-circle {
    -webkit-animation: spin 1.2s ease-in-out infinite;
    animation: spin 1.2s ease-in-out infinite;
    display: block;
    margin: 0 auto;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes logoup {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        fill: transparent;
    }

    20% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        margin-top: -20px;
    }
}

@keyframes logoup {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        fill: transparent;
    }

    20% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        margin-top: -20px;
    }
}

@-webkit-keyframes bglogofade {
    0% {
        fill: transparent;
    }

    100% {
        fill: #fff;
    }
}

@keyframes bglogofade {
    0% {
        fill: transparent;
    }

    100% {
        fill: #fff;
    }
}

@-webkit-keyframes innerlogofade {
    0% {
        fill: #fff;
    }

    40% {
        fill: transparent;
    }

    50% {
        fill: transparent;
    }

    100% {
        fill: transparent;
    }
}

@keyframes innerlogofade {
    0% {
        fill: #fff;
    }

    40% {
        fill: transparent;
    }

    50% {
        fill: transparent;
    }

    100% {
        fill: transparent;
    }
}

/*----------------------------------------------
    Fixed Area include:
  ----------------------------------------------
    1. Table #
    2. Header
    3. Selected delivery address & time date
    4. Menu Cards (ex... pizza, burger, etc...)
    5. All Sub Options
------------------------------------------------*/
.fixed-area {
    position: fixed;
    background: #ececec;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
}

.all_options {
    /*padding: 8px 16px;*/
}

/*-----------------------------------------------
    Table #
------------------------------------------------*/
.__table {
    background: #000000;
    padding: 2px 0px;
    text-align: center;
}

.__table p {
    font-size: 10px;
    line-height: 13px;
    font-weight: 500;
    margin: 0;
    color: #fff;
}

/*-----------------------------------------------
    Header / Navbar
------------------------------------------------*/
.navbar {
    background: #d91a31;
    padding: 0;
}

.navbar.navbar-1 {
    height: 59px;
}

.navbar.navbar-2 {
    height: 42px;
    padding: 0px 16px;
    z-index: 9999;
}

.navbar.navbar-2 .nav-brand {
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
    color: #333;
}

.navbar.navbar-2 .nav-brand img {
    height: 29.3px;
}

.navbar.navbar-2 .navbar-center {
    width: 100%;
    text-align: center;
}

.navbar.navbar-2 .navbar-right,
.navbar.navbar-2 .navbar-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.navbar.navbar-2.for__cart {
    background: #fff;
    /*  height: 59px;*/
}

.navbar.navbar-2.for__cart .nav-brand {
    color: #2a2a2a;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.color-white {
    color: white !important;
}

/*-----------------------------------------------
    Header / Humber Menu Icon
------------------------------------------------*/
.hamburger-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 1.2rem;
    width: 20px;
    cursor: pointer;
}

.hamburger-menu .bar-top,
.hamburger-menu .bar-middle,
.hamburger-menu .bar-bottom {
    height: 2px;
    background: white;
    border-radius: 5px;
    margin: 2px 0;
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.hamburger-menu.active .bar-top {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hamburger-menu.active .bar-middle {
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem);
    opacity: 0;
}

.hamburger-menu.active .bar-bottom {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/*-----------------------------------------------
    Header / Side Menu
------------------------------------------------*/
.side__menu {
    position: fixed;
    display: none;
    right: 0;
    top: 59px;
    height: calc(100% - 59px);
    background: #fff;
    -webkit-box-shadow: 4px 0 3px -2px rgba(20, 20, 20, 0.048);
    box-shadow: 4px 0 3px -2px rgba(20, 20, 20, 0.048);
    width: 283px;
    z-index: 999;
}

.side__menu ul {
    list-style: none;
    padding: 19px 35px;
    margin: 0;
}

.side__menu ul:first-of-type {
    border-bottom: 1px solid #eeeeee;
}

.side__menu ul li {
    display: block;
    width: 213px;
}

.side__menu ul li a {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #2a2a2a;
}

.side__menu ul.__go-in li:first-of-type {
    margin-bottom: 7px;
}

.side__menu ul.__go-in li a.sign-in {
    padding: 10px 30px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border-radius: 6px;
    background: #d91a31;
    color: #fff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
    width: 98px;
}

.side__menu ul.__explore li {
    margin-bottom: 14px;
}

.side__menu ul.__explore li:last-of-type {
    margin-bottom: 0px;
}

/*-----------------------------------------------
    Hero Banner
------------------------------------------------*/
.hero-banner img {
    width: 100%;
}

/*-----------------------------------------------
    Main Area
------------------------------------------------*/
.pizzahut {
    position: absolute;
    bottom: 38px;
    display: flex;
    width: 100%;
}

.pizzahut .main__menu {
    display: flex;
    width: 100%;
}

.pizzahut .main__menu a {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: medium;
    padding: 12px;
    color: #fff;
    background: #d91a31;
    border-radius: 6px;
    font-size: 19px;
    line-height: 24px;
}

.pizzahut .main__menu .more__options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 9px;
}

.pizzahut .main__menu .more__options a {
    font-size: 16px;
    line-height: 21px;
    padding: 12px;
}

.pizzahut .main__menu .more__options a svg {
    margin-right: 4px;
}

.pizzahut .main__menu .more__options a:first-of-type {
    background: #555555;
    margin-right: 4.5px;
}

.pizzahut .main__menu .more__options a:last-of-type {
    background: #fff;
    margin-left: 4.5px;
    color: #2a2a2a;
}

.pizzahut .sign__in,
.pizzahut .sign__up {
    padding: 10px 0px 64px 0px;
    max-width: 335px;
    margin: 0 auto;
}

.pizzahut .sign__in h1,
.pizzahut .sign__up h1 {
    font-size: 22px;
    line-height: 29px;
    font-weight: bold;
    color: #2a2a2a;
}

.pizzahut .sign__in form,
.pizzahut .sign__up form {
    margin-top: 19px;
}

.pizzahut .sign__in form label,
.pizzahut .sign__in form input,
.pizzahut .sign__in form .forgot__password,
.pizzahut .sign__up form label,
.pizzahut .sign__up form input,
.pizzahut .sign__up form .forgot__password {
    font-size: 12px;
    line-height: 16px;
    display: block;
}

.pizzahut .sign__in form label,
.pizzahut .sign__in form input,
.pizzahut .sign__up form label,
.pizzahut .sign__up form input {
    width: 100%;
    outline: none;
    background: transparent;
}

.pizzahut .sign__in form label,
.pizzahut .sign__up form label {
    font-weight: 500;
    margin-bottom: 7px;
    color: #555555;
}

.pizzahut .sign__in form input,
.pizzahut .sign__up form input {
    font-weight: 400;
    padding: 12px 17px;
    color: #bdbdbd;
    border-radius: 4px;
    border: 1px solid #cccccc;
    color: #2a2a2a;
}

.pizzahut .sign__in form input::-webkit-input-placeholder,
.pizzahut .sign__up form input::-webkit-input-placeholder {
    color: #bdbdbd;
}

.pizzahut .sign__in form input:-ms-input-placeholder,
.pizzahut .sign__up form input:-ms-input-placeholder {
    color: #bdbdbd;
}

.pizzahut .sign__in form input::-ms-input-placeholder,
.pizzahut .sign__up form input::-ms-input-placeholder {
    color: #bdbdbd;
}

.pizzahut .sign__in form input::placeholder,
.pizzahut .sign__up form input::placeholder {
    color: #bdbdbd;
}

.pizzahut .sign__in form input[type="submit"],
.pizzahut .sign__up form input[type="submit"] {
    background: #d91a31;
    color: #fff;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.29px;
    border-radius: 8px;
    /*border: 1px solid #d91a31;*/
}

.pizzahut .sign__in form .forgot__password,
.pizzahut .sign__up form .forgot__password {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
    color: #bdbdbd;
}

.pizzahut .sign__in form .connect__with,
.pizzahut .sign__up form .connect__with {
    padding: 30px 0px 40px 0px;
    text-align: center;
}

.pizzahut .sign__in form .connect__with p,
.pizzahut .sign__up form .connect__with p {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    margin-bottom: 11px;
}

.pizzahut .sign__in form .connect__with .connect__options,
.pizzahut .sign__up form .connect__with .connect__options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.pizzahut .sign__in form .connect__with .connect__options a,
.pizzahut .sign__up form .connect__with .connect__options a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 129px;
    height: 48px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    letter-spacing: 0.24px;
}

.pizzahut .sign__in form .connect__with .connect__options a svg,
.pizzahut .sign__up form .connect__with .connect__options a svg {
    margin-right: 9px;
}

.pizzahut .sign__in form .connect__with .connect__options a.facebook,
.pizzahut .sign__up form .connect__with .connect__options a.facebook {
    background: #2c58b2;
    margin-right: 6px;
}

.pizzahut .sign__in form .connect__with .connect__options a.google,
.pizzahut .sign__up form .connect__with .connect__options a.google {
    background: #d25127;
    margin-left: 6px;
}

.pizzahut .sign__in .goto-sign__up,
.pizzahut .sign__up .goto-sign__up {
    font-size: 12px;
    line-height: 17px;
    font-weight: 500;
    color: #555555;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    margin-top: 26px;
}

.pizzahut .sign__in .goto-sign__up span,
.pizzahut .sign__up .goto-sign__up span {
    color: #d91a31;
}

.pizzahut .delivery__self-pickup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 2px;
    /* Choose option */
}

.pizzahut .delivery__self-pickup .option {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    height: 45px;
    background: #f6f6f6;
    -webkit-box-shadow: 0px 3px 5px #00000010;
    box-shadow: 0px 3px 5px #00000010;
}

.pizzahut .delivery__self-pickup .option span,
.pizzahut .delivery__self-pickup .option svg {
    opacity: 0.6;
}

.pizzahut .delivery__self-pickup .option span {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #000000;
    letter-spacing: 0px;
}

.pizzahut .delivery__self-pickup .option svg {
    margin-right: 8px;
}

.pizzahut .delivery__self-pickup .option.active {
    border-bottom: 2px solid #d91a31;
    background: #fff;
}

.pizzahut .delivery__self-pickup .option.active span,
.pizzahut .delivery__self-pickup .option.active svg {
    opacity: 1;
}

.pizzahut .search__locations,
.pizzahut .pickup__locations {
    margin-bottom: 100px;
    // display: none;
}

.pizzahut .search__locations {
    background: #fff;
    display: block;
    padding: 30px;
    width: 100%;
}

.pizzahut .search__locations .row {
    margin: 0;
    width: 100%;
}

.pizzahut .search__locations .row .col-sm-12 {
    padding: 0;
}

.pizzahut .search__locations label,
.pizzahut .search__locations input,
.pizzahut .search__locations button {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

.pizzahut .search__locations .locations {
    position: relative;
}

.pizzahut .search__locations .locations label {
    margin-bottom: 7px;
    font-weight: 500;
}

.pizzahut .search__locations .locations input,
.pizzahut .search__locations .locations button {
    border-radius: 4px;
    outline: none;
    border: none;
}

.pizzahut .search__locations .locations input {
    display: block;
    width: 100%;
    height: 41px;
    padding: 0px 13px;
    border: 1px solid #909090;
    border-radius: 4px;
}

.pizzahut .search__locations .locations input:focus+.locations__list {
    display: block;
}

.pizzahut .search__locations .locations button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 41px;
    padding: 0px 11px;
    background: #d91a31;
}

.pizzahut .search__locations .locations .locations__list {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 69px;
    width: 100%;
    background: #fff;
    margin-top: 10px;
    list-style: none;
    border: 1px solid #cccccc;
    border-radius: 4px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    max-height: 280px;
    overflow-y: scroll;
    display: none;
    z-index: 9;
}

.pizzahut .search__locations .locations .locations__list li a {
    display: block;
    width: 100%;
    padding: 11px 13px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    border-bottom: 1px solid #eeeeee;
    color: #2a2a2a;
}

.pizzahut .search__locations .locations .locations__list li:last-of-type a {
    border-bottom: none;
}

.pizzahut .search__locations .locations:nth-of-type(2) input {
    border-color: #bdbdbd;
}

.pizzahut .search__locations .locations:nth-of-type(2) input::-webkit-input-placeholder {
    border-color: #bdbdbd;
}

.pizzahut .search__locations .locations:nth-of-type(2) input:-ms-input-placeholder {
    border-color: #bdbdbd;
}

.pizzahut .search__locations .locations:nth-of-type(2) input::-ms-input-placeholder {
    border-color: #bdbdbd;
}

.pizzahut .search__locations .locations:nth-of-type(2) input::placeholder {
    border-color: #bdbdbd;
}

.pizzahut .search__locations input[type="submit"] {
    background: #d91a31;
    color: #fff;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.29px;
    border-radius: 8px;
    border: 1px solid #d91a31;
    width: 100%;
    padding: 15px;
}

.pizzahut .pickup__locations {
    background: #fff;
    /* Outlet Card -> Start */
    /* Outlet Card -> End */
}

.pizzahut .pickup__locations .main__heading {
    padding: 18px 20px 10px 20px;
}

.pizzahut .pickup__locations .main__heading h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #909090;
    margin: 0;
}

.pizzahut .pickup__locations .outlet {
    padding: 11px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid #eeeeee;
}

.pizzahut .pickup__locations .outlet:last-of-type {
    border-bottom: 1px solid #eeeeee;
}

.pizzahut .pickup__locations .outlet .__details,
.pizzahut .pickup__locations .outlet .__search {
    width: 100%;
}

.pizzahut .pickup__locations .outlet .__details {
    min-width: 278px;
}

@media screen and (max-width: 576px) {
    .pizzahut .pickup__locations .outlet .__details {
        min-width: 278px;
    }
}

@media screen and (max-width: 400px) {
    .pizzahut .pickup__locations .outlet .__details {
        min-width: 250px;
    }
}

.pizzahut .pickup__locations .outlet .__details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 278px;
}

.pizzahut .pickup__locations .outlet .__details .__image {
    margin-right: 15px;
}

.pizzahut .pickup__locations .outlet .__details .__image img {
    width: 72px;
    height: auto;
}

.pizzahut .pickup__locations .outlet .__details .__description h4,
.pizzahut .pickup__locations .outlet .__details .__description p {
    margin: 0;
}

.pizzahut .pickup__locations .outlet .__details .__description h4 {
    font-size: 13px;
    line-height: 17px;
    font-weight: 700;
    color: #2a2a2a;
    margin-bottom: 4.7px;
}

.pizzahut .pickup__locations .outlet .__details .__description h4 svg {
    margin-right: 5.6px;
}

.pizzahut .pickup__locations .outlet .__details .__description p {
    font-size: 9px;
    line-height: 14px;
    color: #919191;
}

.pizzahut .pickup__locations .outlet .__search a {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    padding: 8px 20px;
    background: #d91a31;
    color: #fff;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 6px;
    margin-left: auto;
    margin-right: 0px;
}

/* Continue as Guest */
.continue__as__guest {
    display: block;
    width: 100%;
    padding: 22px;
    background: #555555;
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 21px;
}

.continue__as__guest__wrapper {
    a:hover {
        color: #cdd3dd;
    }
}


.sign__up form .connect__with {
    padding: 25px 0px 40px 0px !important;
}

/*-----------------------------------------------
    Selected Delivery & Time
------------------------------------------------*/
.selected__deliver-and--date {
    padding: 0px 5px;
}

.selected__deliver-and--date .row,
.selected__deliver-and--date .col {
    margin: 0;
    padding: 0;
}

.selected__deliver-and--date span,
.selected__deliver-and--date p {
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.selected__deliver-and--date span {
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0.21px;
    color: #8d8d8d;
}

.selected__deliver-and--date span svg {
    margin-right: 4px;
}

.selected__deliver-and--date p {
    margin-top: 5px;
}

.selected__deliver-and--date p span {
    line-height: 13px;
    font-size: 10px;
    line-height: 13px;
    color: #555555;
}

.selected__deliver-and--date p svg {
    margin-left: 4px;
}

.selected__deliver-and--date .selected__date {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
}

/*-----------------------------------------------
   Menu Options
------------------------------------------------*/
.menu__cards {
    margin-top: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    overflow-x: auto;
}

.menu__cards .menu__card {
    min-width: 73px;
    width: 73px;
    padding: 12px 0px 11px 0px;
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    text-align: center;
    margin-right: 8px;
}

.menu__cards .menu__card:last-of-type {
    margin-right: 0px;
}

.menu__cards .menu__card svg {
    margin-bottom: 4px;
}

.menu__cards .menu__card svg #outer__path {
    fill: #f5f5f5;
}

.menu__cards .menu__card svg #inner__path {
    fill: #d4d4d4;
}

.menu__cards .menu__card span {
    color: #8d8d8d;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
}

.menu__cards .menu__card.active {
    background: #d91a31;
    border: #d91a31;
}

.menu__cards .menu__card.active svg #outer__path {
    fill: #e25a6b;
}

.menu__cards .menu__card.active svg #inner__path {
    fill: #fff;
}

.menu__cards .menu__card.active span {
    color: #fff;
}

/*-----------------------------------------------
  Submenu Options and Grid Selection
------------------------------------------------*/
.sub-menu__grid-options {
    margin-top: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.sub-menu__grid-options .sub-menu__options {
    width: 100%;
    padding: 5px 5px;
    overflow: auto;
}

.sub-menu__grid-options .sub-menu__options ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    overflow-x: auto;
}

.sub-menu__grid-options .sub-menu__options ul li {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #8d8d8d;
    white-space: nowrap;
}

.sub-menu__grid-options .sub-menu__options ul li:last-of-type {
    margin-right: 10px;
}

.sub-menu__grid-options .sub-menu__options ul li.active {
    color: #2a2a2a;
    position: relative;
}

.sub-menu__grid-options .sub-menu__options ul li.active:after {
    content: "";
    display: block;
    width: 100%;
    border: 1px solid #d91a31;
}

.sub-menu__grid-options .grid__options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin-left: auto;
    margin-right: 0;
}

.sub-menu__grid-options .grid__options .grid__ {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 34px;
    height: 34px;
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    margin-right: 5px;
}

.sub-menu__grid-options .grid__options .grid__:last-of-type {
    margin-right: 0px;
}

/*-----------------------------------------------
    Main -> Items
------------------------------------------------*/
.main {
    padding: 0px 16px;
    margin-top: 248px;
}

.main.dine-in {
    margin-top: 250px;
    overflow-y: auto;
}

/*-----------------------------------------------
  All Items & Item Card
------------------------------------------------*/
.all_items {
    margin: 6px 0px;
}

.all_items .row,
.all_items .column {
    margin: 0;
    padding: 0;
}

.all_items .column {
    margin-bottom: 0px;
}

.all_items .column:nth-child(odd) {
    padding-right: 0px;
}

.all_items .column:nth-child(even) {
    padding-left: 0px;
}

.all_items .item__card {
    background: #fff;
    height: 100%;
    border-radius: 10px;
}

.all_items .item__card .item__image {
    z-index: 9;
    position: relative;
}

/*
.all_items .item__card .item__image img {
  width: 100%;
}*/

.all_items .item__card .item__image .sold__out {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #858585a6;
    border-radius: 10px;
}

.all_items .item__card .item__image .sold__out span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    font-size: 13px;
    line-height: 17px;
    color: #fff;
    background: #555555;
    border-radius: 9px 9px 0px 0px;
}

.all_items .item__card .including__stuff {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.all_items .item__card .including__stuff.inside__image {
    position: absolute;
    bottom: 6px;
    right: 6px;
}

.all_items .item__card .including__stuff li {
    margin-right: 3px;
}

.all_items .item__card .including__stuff li:last-of-type {
    margin-right: 0px;
}

.all_items .item__card .item__details {
    padding: 8px;
    text-align: center;
}

.all_items .item__card .item__details h3,
.all_items .item__card .item__details p {
    margin: 0;
}

.all_items .item__card .item__details h3 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #2a2a2a;
    margin-bottom: 4px;
    letter-spacing: -0.68px;
}

.all_items .item__card .item__details p span {
    font-size: 15px;
    line-height: 20px;
    color: #888888;
    font-weight: 500;
}

.all_items .item__card .item__details p span:first-of-type {
    color: #bf1b2f;
}

.all_items .item__card .item__details .item__name__includings {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 2px;
}

.all_items .item__card .item__details .item__name__includings h3 {
    margin-right: 10px;
    text-align: left;
}

.all_items .item__card .added_item__count {
    position: absolute;
    bottom: 7px;
    right: 7px;
    width: 80px;
    padding: 5px 0px 6px 0px;
    border-radius: 6px;
    background: #d91a31;
    border: 1px solid #f17c7c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.all_items .item__card .added_item__count span {
    color: #fff;
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    display: block;
}

.all_items .item__card .added_item__count span:nth-of-type(2) {
    width: 40px;
    text-align: center;
}

.all_items.list__layout .column {
    padding: 0;
}

.all_items.list__layout .item__card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    height: 100%;
    padding: 0;
}

.all_items.list__layout .item__card p {
    text-align: left;
}

.all_items.list__layout .item__card .item__descriptions {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #888888;
    margin-bottom: 5px;
}

.all_items.list__layout .item__card .item__image .sold__out {
    border-radius: 10px;
}

/*.all_items.list__layout .item__card .item__image img {
  width: unset;
  height: 100%;
  min-width: 70px;
}*/

.all_items.list__layout .item__card .item__details {
    padding: 5px;
}

@media screen and (max-width: 400px) {
    .all_items.list__layout .item__card .item__details {
        padding: 5px;
    }
}

.discard__item {
    position: absolute;
    right: 11px;
    top: 8px;
    z-index: 10;
}

/*-----------------------------------------------
    Checkout -> Items
------------------------------------------------*/
.checkout_items {
    background: #bf1b2f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: calc(100% - 32px);
    left: 16px;
    height: 52px;
    margin: 0 auto;
    position: absolute;
    margin: 0 auto;
    border-radius: 8px;
    bottom: 50px;
    color: #fff;
    padding: 0px 16px;
    z-index: 10;
}

.checkout_items span {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.29px;
}

.checkout_items .total__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.checkout_items .total__items svg {
    margin-right: 9px;
}

.checkout_items .items__price {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: 0px;
}

@-webkit-keyframes ball-y {
    0% {
        -webkit-transform: translateY(0px);
        -webkit-animation-timing-function: ease-in;
    }

    28% {
        -webkit-transform: translateY(-45px);
        -webkit-animation-timing-function: ease-in;
    }

    56% {
        -webkit-transform: translateY(-35px);
        -webkit-animation-timing-function: ease-in;
    }

    56% {
        -webkit-transform: translateY(-15px);
        -webkit-animation-timing-function: ease-in;
    }

    84% {
        -webkit-transform: translateY(-10px);
        -webkit-animation-timing-function: ease-in;
    }

    14%,
    42%,
    75%,
    100% {
        -webkit-transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
    }
}

/*-----------------------------------------------
    Cart
------------------------------------------------*/
.cart {
    background: #fff;
    padding-top: 59px;
    /*-----------------------------------------------
    Cart Header -> Modification in main Header
  ------------------------------------------------*/
    /*-----------------------------------------------
    Cart -> All Options
  ------------------------------------------------*/
    /*-----------------------------------------------
    Cart -> Cart Items
  ------------------------------------------------*/
    /*-----------------------------------------------
    Cart -> Payment Methods
  ------------------------------------------------*/
    /*-----------------------------------------------
    Cart -> Enter Card Details
  ------------------------------------------------*/
    /*-----------------------------------------------
    Cart -> Order Processing/Loader
  ------------------------------------------------*/
    /*-----------------------------------------------
    Cart -> Continue as Guest
  ------------------------------------------------*/
}

.cart.cart-1 {
    margin-bottom: 470px;
}

.cart.cart-2 {
    margin-bottom: 240px;
}

.cart.cart-3 {
    margin-bottom: 70px;
}

.cart .for__cart {
    border-bottom: 1px solid #eeeeee;
    position: fixed;
    background: #fff;
    z-index: 9;
    top: 0;
    width: 100%;
    left: 0;
}

.cart .for__cart.shedow {
    -webkit-box-shadow: 0px 3px 13px #00000011;
    box-shadow: 0px 3px 13px #00000011;
}

.cart .all_options .sub-menu__grid-options {
    padding: 10px 0px;
}

.cart .cart__items {
    height: 200px;
    overflow-y: auto;
}

.cart .cart__items .row,
.cart .cart__items .column {
    margin: 0;
    padding: 0;
}

.cart .cart__items .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cart .cart__items .cart__item {
    border-bottom: 1px solid #eeeeee;
    padding: 13px 16px;
}

.cart .cart__items .cart__item:first-of-type {
    border-top: 1px solid #eeeeee;
}

.cart .cart__items .cart__item h3,
.cart .cart__items .cart__item p {
    margin: 0;
}

.cart .cart__items .cart__item .item__description h3 {
    margin-top: -8px;
    margin-bottom: 5px;
}

.cart .cart__items .cart__item .item__description h3 svg {
    margin-right: 2px;
}

.cart .cart__items .cart__item .item__description h3 span {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: #2a2a2a;
    padding-left: 1em;
}

.cart .cart__items .cart__item .item__description p {
    font-size: 9px;
    line-height: 12px;
    color: #d91a31;
    padding: 0px 22px;
}

.cart .cart__items .cart__item .item__description p svg {
    margin-left: 4px;
}

.cart .cart__items .cart__item .item__count {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #cccccc;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 4px;
    width: 73px;
    min-width: 73px;
    margin: 0 auto;
    height: 32px;
}

.cart .cart__items .cart__item .item__count span {
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    color: #2a2a2a;
}

.cart .cart__items .cart__item .item__count span.count__items {
    display: block;
    text-align: center;
    width: 35px;
}

.cart .cart__items .cart__item .item__price span {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #2a2a2a;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
}

.cart .payment__methods {
    background: #fff;
    padding: 26px 16px;
}

.cart .payment__methods h2 {
    margin: 0 0 6px 0;
    font-size: 14px;
    line-height: 18px;
    color: #2a2a2a;
}

.cart .payment__methods .all__methods {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

.cart .payment__methods .all__methods .payment__method {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 67px;
    border-radius: 7px;
    border: 1px solid #b7b7b7;
    width: 100%;
}

.cart .payment__methods .all__methods .payment__method.paypal {
    margin: 0px 8px;
}

.cart .payment__methods .all__methods .payment__method.active {
    border: 2px solid #d91a31;
    background: #d91a311a;
}

.cart .card__details {
    padding: 0px 16px 30px 16px;
}

.cart .card__details .row,
.cart .card__details .col,
.cart .card__details .col-8,
.cart .card__details .col-12 {
    margin: 0;
    padding: 0;
}

.cart .card__details .col-8 {
    padding-right: 20px;
}

.cart .card__details input,
.cart .card__details label {
    display: block;
}

.cart .card__details label {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #2a2a2a;
    margin-bottom: 5px;
}

.cart .card__details {

    input,
    textarea {
        width: 100%;
        border: 1px solid #cccccc;
        outline: none;
        padding: 10px 12px;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.29px;
        border-radius: 4px;
        color: #919191;
        height: 39px;
    }

    textarea {
        height: unset;
    }
}

.cart .card__details .card__number {
    position: relative;
}

.cart .card__details .card__number img {
    position: absolute;
    right: 8px;
    top: 8px;
}

.cart .order__processing {
    padding: 5px 16px;
    background: #fafafa;
    text-align: center;
}

.cart .order__processing .loading {
    width: 210px;
    height: 20px;
    border: 1px solid #d91a31;
    padding: 2px;
    border-radius: 10px;
    margin: 0 auto;
}

.cart .order__processing .loading .process {
    background: #d91a31;
    border-radius: 8px;
    height: 14px;
    width: 20px;
}

.cart .order__processing .loading .process.expand {
    width: 100%;
    -webkit-transition: width 1.5s ease-in-out;
    transition: width 1.5s ease-in-out;
}

.cart .order__processing h2,
.cart .order__processing p {
    margin: 0;
    display: block;
}

.cart .order__processing h2 {
    font-size: 17px;
    font-weight: bold;
    line-height: 22px;
    color: #2a2a2a;
    margin: 14px 0px 3px 0px;
}

.cart .order__processing p {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.13px;
    color: #888888;
}

.cart .continue__browsing {
    display: block;
    width: 100%;
    padding: 19px;
    background: #bf1b2f;
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 21px;
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
}

/*-----------------------------------------------
    Cart -> Order/Ordered Details
  ------------------------------------------------*/
.order__dropdown .order__summary {
    padding: 16px 16px 13px 16px;
    background: #ececec;
}

.order__dropdown .order__summary h6 {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    color: #888888;
    letter-spacing: 0.17px;
    font-weight: 500;
}

.order__dropdown .dropdown__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 36px;
    color: #2a2a2a;
    padding: 0px 16px;
    background: #dadada;
}

.order__dropdown .dropdown__head span {
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.order__dropdown .dropdown__head svg {
    margin-left: auto;
    margin-right: 0;
}

.order__dropdown.details .dropdown__body {
    padding: 14px 16px 20px 16px;
}

.order__dropdown.details .dropdown__body span {
    display: block;
    color: #909090;
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
}

.order__dropdown .dropdown__body {
    padding: 14px 0px 0px 0px;
    display: none;
}

.order__dropdown .dropdown__body span,
.order__dropdown .dropdown__body h3,
.order__dropdown .dropdown__body h5,
.order__dropdown .dropdown__body h2,
.order__dropdown .dropdown__body p {
    margin: 0;
}

.order__dropdown .dropdown__body .about__order {
    width: 100%;
    padding: 14px 19px;
    border: 1px solid #bdbdbd;
    background: #fff;
    -webkit-box-shadow: 0px 3px 12px #0000001a;
    box-shadow: 0px 3px 12px #0000001a;
    border-radius: 9px;
}

.order__dropdown .dropdown__body .about__order:first-of-type {
    margin-bottom: 10px;
}

.order__dropdown .dropdown__body .about__order h3 {
    color: #2a2a2a;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
}

.order__dropdown .dropdown__body .about__order h5 {
    color: #2a2a2a;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.order__dropdown .dropdown__body .about__order .more__details {
    padding: 12px 0px 12px 0px;
    border-bottom: 1px solid #eeeeee;
}

.order__dropdown .dropdown__body .about__order .more__details:last-of-type {
    padding: 12px 0px 0px 0px;
    border-bottom: none;
}

.order__dropdown .dropdown__body .about__order .more__details:first-of-type {
    padding: 0px 0px 12px 0px;
}

.order__dropdown .dropdown__body .about__order .more__details:first-of-type span {
    margin-bottom: 4px;
}

.order__dropdown .dropdown__body .about__order.transection__details {
    /*  max-width: 272px; */
    padding: 14px 19px 12px 19px;
}

.order__dropdown .dropdown__body .about__order.transection__details span {
    margin-bottom: 1.5px;
}

.order__dropdown .dropdown__body .order__overview {
    margin-bottom: 15px;
    padding: 0px 16px;
}

.order__dropdown .dropdown__body .order__overview h2 {
    font-size: 17px;
    line-height: 22px;
    font-weight: bold;
    color: #2a2a2a;
    margin-bottom: 6px;
}

.order__dropdown .dropdown__body .order__overview p,
.order__dropdown .dropdown__body .order__overview .__table {
    font-size: 12px;
    line-height: 16px;
    color: #a6a6a6;
    margin-bottom: 4px;
}

.order__dropdown .dropdown__body .order__overview p.__items,
.order__dropdown .dropdown__body .order__overview .__table.__items {
    text-transform: uppercase;
    letter-spacing: 0.29px;
    font-weight: bold;
}

.order__dropdown .dropdown__body .order__overview .__table {
    background: transparent;
}

.order__dropdown .dropdown__body .ordered__items {
    padding: 0px 16px;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card {
    padding: 12px 0px 10px 0px;
    border-top: 1px solid #eeeeee;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card:last-of-type {
    border-bottom: 1px solid #eeeeee;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card h5,
.order__dropdown .dropdown__body .ordered__items .ordered__item__card p,
.order__dropdown .dropdown__body .ordered__items .ordered__item__card span {
    margin: 0;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card h5 {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: #2a2a2a;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card .ordered__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card .ordered__item span {
    display: block;
    margin-left: auto;
    margin-right: 0;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card .ordered__item.ordered__item__name__price {
    margin-bottom: 7px;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card .ordered__item.ordered__item__name__price span {
    color: #555555;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card .ordered__item.ordered__item__desc__price {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card .ordered__item.ordered__item__desc__price p {
    font-size: 13px;
    line-height: 18px;
    color: #b1b1b1;
    display: block;
    width: 70%;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card .ordered__item.ordered__item__desc__price span {
    color: #bdbdbd;
    font-size: 11px;
    line-height: 18px;
    display: block;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.order__dropdown .dropdown__body .ordered__items .ordered__item__card a {
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    background: #d91a31;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 16px;
    min-width: 90px;
    border-radius: 6px;
    margin-left: auto;
    margin-right: 0;
}

.order__dropdown .dropdown__body .ordered__total {
    padding: 13px 16px 26px 16px;
}

.order__dropdown .dropdown__body .ordered__total span {
    font-size: 14px;
    line-height: 18px;
    color: #9d9d9d;
}

/*.order__dropdown .dropdown__body .ordered__total .row .column {
  margin-bottom: 4px;
}*/

.order__dropdown .dropdown__body .ordered__total .row .column:last-of-type {
    margin-bottom: 0px;
}

.order__dropdown .dropdown__body .ordered__total .row .column:nth-of-type(2) span {
    display: block;
    margin-left: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 0;
}

.order__dropdown .dropdown__body .ordered__total .row.grand__total {
    margin-top: 5px;
}

.order__dropdown .dropdown__body .ordered__total .row.grand__total span {
    font-size: 16px;
    line-height: 22px;
    color: #2a2a2a;
    font-weight: bold;
}

.order__dropdown .dropdown__body .ordered__action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.order__dropdown .dropdown__body .ordered__action a {
    display: block;
    width: 100%;
    text-align: center;
    padding: 14px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #2a2a2a;
}

.order__dropdown .dropdown__body .ordered__action a svg {
    margin-right: 9px;
}

.order__dropdown .dropdown__body .ordered__action a.repeat__order {
    background: #bf1b2f;
    color: #fff;
}

.order__dropdown .dropdown__body .ordered__action a.show__qrcode {
    background: #dadada;
    color: #fff;
}

.order__dropdown.active .dropdown__head {
    color: #fff;
    background: #d91a31;
}

.order__dropdown.active .dropdown__head svg {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.order__dropdown.active .dropdown__head svg path {
    fill: #fff;
}

.order__dropdown.active .dropdown__body {
    display: block;
}

/*-----------------------------------------------
    Success Notification
------------------------------------------------*/
.success__notification {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #3ab021;
    text-align: center;
    z-index: 9999;
    padding: 13px;
    color: #fff;
}

.success__notification p {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.12px;
}

/*-----------------------------------------------
    Cart -> Making Bottom Fixed that include:
-------------------------------------------------
    1. Hungary for more (Additional Items)
    2. Total Calculation & Checkout
------------------------------------------------*/
.bottom__area {
    position: fixed;
    bottom: 41px;
    width: 100%;
    z-index: 9;
}

/*-----------------------------------------------
    Cart -> Hungary for more
------------------------------------------------*/
.hungary__for__more {
    padding: 10px 0px;
    background: #f8f8f8;
}

.hungary__for__more p,
.hungary__for__more h2,
.hungary__for__more h3 {
    margin: 0;
}

.hungary__for__more h2 {
    padding: 0px 16px;
    color: #a2a2a2;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 9px;
}

.hungary__for__more .more__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0px 16px;
}

.hungary__for__more .more__items .more__item__card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #fff;
    margin-right: 11px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 245px;
    min-width: 245px;
    padding-right: 14px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 3px 19px #0000000d;
    box-shadow: 0px 3px 19px #0000000d;
}

.hungary__for__more .more__items .more__item__card:last-of-type {
    margin-right: 0px;
}

.hungary__for__more .more__items .more__item__card img {
    margin-right: 12px;
}

.hungary__for__more .more__items .more__item__card .item__detail h3 {
    line-height: 18px;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #2a2a2a;
    margin-bottom: 5px;
}

.hungary__for__more .more__items .more__item__card .item__detail p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #888888;
}

.hungary__for__more .more__items .more__item__card .item__detail p span:first-of-type {
    color: #d91a31;
}

.hungary__for__more .more__items .more__item__card .add__to__cart {
    cursor: pointer;
    margin-left: 12px;
    width: 37px;
    height: 37px;
}

.hungary__for__more .more__items .more__item__card .add__to__cart svg {
    -webkit-transform: scale(7);
    transform: scale(7);
}

/*-----------------------------------------------
    Cart -> Total Calculations
------------------------------------------------*/
.total__calculations {
    background: #fff;
    padding: 10px 10px 5px 10px;
}

.total__calculations .promo__code {
    margin-bottom: 22px;
}

.total__calculations .promo__code .enter__promo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.total__calculations .promo__code .enter__promo input {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: #9d9d9d;
    display: block;
    border-radius: 4px;
    height: 45px;
    outline: none;
    background: #f1f1f1;
    border: none;
    padding: 0px 15px;
}

.total__calculations .promo__code .enter__promo input[type="submit"] {
    width: 74px;
    background: #909090;
    color: #fff;
    margin-left: 5px;
    font-weight: 500;
}

.total__calculations .total__price__including__tax__and__delivery .row,
.total__calculations .total__price__including__tax__and__delivery .column {
    margin: 0;
    padding: 0;
}

.total__calculations .total__price__including__tax__and__delivery .row {
    margin-bottom: 5px;
}

.total__calculations .total__price__including__tax__and__delivery .row:last-of-type {
    margin-top: 10px;
}

.total__calculations .total__price__including__tax__and__delivery .__name,
.total__calculations .total__price__including__tax__and__delivery .__price {
    display: block;
    font-size: 14px;
    line-height: 23px;
    color: #262424;
}

.total__calculations .total__price__including__tax__and__delivery .__name.grand__total,
.total__calculations .total__price__including__tax__and__delivery .__price.grand__total {
    font-size: 19px;
    font-weight: bold;
    line-height: 25px;
    letter-spacing: -0.46px;
    color: #2a2a2a;
}

.total__calculations .total__price__including__tax__and__delivery .__price {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: 0px;
}

.total__calculations .checkout__final a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 42px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #bf1b2f;
    font-size: 17px;
    line-height: 22px;
    color: #fff;
    letter-spacing: 0.29px;
    border-radius: 8px;
    margin-top: 5px;
}

/*-----------------------------------------------
    Checkbox Round & Square
------------------------------------------------*/
.all__checkbox {
    padding: 0px 15px;
    margin: 15px 0px 17px 0px;
}

.all__checkbox ul {
    margin: 0;
    padding: 0;
    list-style: none;
    min-height: 25px;
    overflow-y: auto;
}

.all__checkbox ul::-webkit-scrollbar-track {
    background-color: #d8d8d8;
}

.all__checkbox ul::-webkit-scrollbar {
    width: 5px;
}

.all__checkbox ul::-webkit-scrollbar-thumb {
    background-color: #909090;
}

.all__checkbox ul li .each__checkbox {
    margin-bottom: 7.5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.all__checkbox ul li .each__checkbox .extra__price {
    margin-left: auto;
    margin-right: 0px;
}

.all__checkbox ul li .each__checkbox .extra__price span {
    font-size: 13px;
    line-height: 17px;
    color: #555555;
    color: #909090;
}

.all__checkbox ul li .each__checkbox .round {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: contents;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    left: -10px;
}

.all__checkbox ul li .each__checkbox .round label {
    border: 1px solid #cfcfcf;
    /*  border-radius: 50%;*/
    cursor: pointer;
    position: relative;
    width: 20px;
    height: 20px;
}

.all__checkbox ul li .each__checkbox .round label span {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: block;
    left: 32px;
    width: 150px;
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
}

.all__checkbox ul li .each__checkbox .round label span p {
    font-size: 8px;
    line-height: 10px;
    color: #bdbdbd;
    margin-left: 6px;
}

.all__checkbox ul li .each__checkbox .round label:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    /*background: #d91a31;
    border-radius: 50%;*/
    opacity: 0;
}

.all__checkbox ul li .each__checkbox .round.square label {
    border-radius: 0px;
}

.all__checkbox ul li .each__checkbox .round.square label::before {
    border-radius: 0px;
}

.all__checkbox ul li .each__checkbox .round input[type="checkbox"] {
    display: none;
}

.all__checkbox ul li .each__checkbox .round input[type="checkbox"]:checked+label {
    /*border-color: #d91a31;*/
}

.all__checkbox ul li .each__checkbox .round input[type="checkbox"]:checked+label:before {
    opacity: 1;
}

.all__checkbox ul li .each__checkbox.with__add_ons .modifiers {
    left: 20px !important;
    display: none;
}

.all__checkbox ul li .each__checkbox.with__add_ons .modifiers p {
    font-size: 10px;
    line-height: 13px;
    font-weight: 500;
    color: #909090;
}

.all__checkbox ul li .each__checkbox.with__add_ons .round input[type="checkbox"]:checked+label {
    margin-bottom: 12px;
}

.all__checkbox ul li .each__checkbox.with__add_ons .round input[type="checkbox"]:checked+label span {
    color: #2a2a2a !important;
    font-weight: bold;
}

.all__checkbox ul li .each__checkbox.with__add_ons .round input[type="checkbox"]:checked+label .modifiers {
    display: block;
    display: unset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.all__checkbox ul li:last-of-type .timing {
    margin-bottom: 0px;
}

/*-----------------------------------------------
    Pop Ups
------------------------------------------------*/
.popup__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    z-index: 999;
}

.popup__bg .popup__content {
    background: #fff;
    width: calc(100% - 50px);
    max-width: 375px;
    border-radius: 15px;
    margin: 0 auto;
    /* Close Popup */
    /* Date & Time Popup */
    /* Filter Popup */
    /* Deal Popup */
    /* QR Code Popup */
    /* Add sub item popup */
}

.popup__bg .popup__content .sub__dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 36px;
    color: #2a2a2a;
    padding: 0px 16px;
    background: #dadada;
}

.popup__bg .popup__content .sub__dropdown span {
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup__bg .popup__content .sub__dropdown svg {
    margin-left: auto;
    margin-right: 0;
}

.close__popup {
    cursor: pointer;
}

.popup__bg .popup__content .close__popup {
    height: 38px;
    width: 38px;
    background: #bf1b2f;
    border-radius: 50%;
    position: absolute;
    top: -17px;
    right: -16px;
}

.popup__bg .popup__content .close__popup svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.popup__bg .popup__content.__date-time {
    padding: 19px 0px 0px 0px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    // overflow: hidden;
}

.popup__bg .popup__content.__date-time h3 {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 17px;
    text-align: center;
}

.popup__bg .popup__content.__date-time .all__dates {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    padding: 3px 20px;
}

.popup__bg .popup__content.__date-time .all__dates .date {
    min-width: 75px;
    border: 1px solid #cccccc;
    background: #fff;
    border-radius: 4px;
    margin-right: 9px;
    padding: 9px;
}

.popup__bg .popup__content.__date-time .all__dates .date span {
    display: block;
    color: #909090;
    text-align: center;
}

.popup__bg .popup__content.__date-time .all__dates .date span.__day,
.popup__bg .popup__content.__date-time .all__dates .date span.__month {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.45px;
}

.popup__bg .popup__content.__date-time .all__dates .date span.__date {
    font-family: "Oswald";
    font-size: 37px;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 3px;
}

.popup__bg .popup__content.__date-time .all__dates .date.__current {
    background: #d91a31;
}

.popup__bg .popup__content.__date-time .all__dates .date.__current span {
    color: #fff;
}

.popup__bg .popup__content.__date-time .__continue input,
.popup__bg .popup__content.__date-time .__continue a {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    background: #d91a31;
    padding: 12px;
    width: 100%;
    color: #fff;
    outline: none;
    border: none;
    text-align: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.popup__bg .popup__content.self-content .__continue input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.popup__bg .popup__content.filter__popup {
    padding: 25px 0px 40px 0px;
    position: fixed;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 10px 10px 0px 0px;
}

.popup__bg .popup__content.filter__popup .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup__bg .popup__content.filter__popup .row,
.popup__bg .popup__content.filter__popup .column,
.popup__bg .popup__content.filter__popup h3,
.popup__bg .popup__content.filter__popup h2,
.popup__bg .popup__content.filter__popup p,
.popup__bg .popup__content.filter__popup span {
    margin: 0;
    padding: 0;
}

.popup__bg .popup__content.filter__popup .reset {
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    color: #d91a31;
    position: absolute;
    top: 25px;
    left: 20px;
}

.popup__bg .popup__content.filter__popup .popup__heading {
    text-align: center;
}

.popup__bg .popup__content.filter__popup .popup__heading h2 {
    font-size: 20px;
    line-height: 26px;
    color: #2a2a2a;
    font-weight: bold;
}

.popup__bg .popup__content.filter__popup .heading__ h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: -0.29px;
    color: #2a2a2a;
}

.popup__bg .popup__content.filter__popup .heading__ span {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #bdbdbd;
    font-weight: bold;
    margin-left: auto;
    margin-right: 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.popup__bg .popup__content.filter__popup .heading__.categories__selection {
    margin-bottom: 20px;
}

.popup__bg .popup__content.filter__popup .heading__.sortby__heading {
    margin-bottom: 12px;
    padding: 0px 20px;
}

.popup__bg .popup__content.filter__popup .all__categories {
    margin-top: 25px;
    padding: 0px 20px;
}

.popup__bg .popup__content.filter__popup .all__categories .categories__types {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 18px;
}

.popup__bg .popup__content.filter__popup .all__categories .categories__types:last-of-type {
    margin-bottom: 0px;
}

@media screen and (max-width: 394px) {
    .popup__bg .popup__content.filter__popup .all__categories .categories__types {
        margin-bottom: 12px;
    }

    .popup__bg .popup__content.filter__popup .all__categories .categories__types:last-of-type {
        margin-bottom: 0px;
    }
}

.popup__bg .popup__content.filter__popup .all__categories .categories__types .category:nth-of-type(2),
.popup__bg .popup__content.filter__popup .all__categories .categories__types .category:last-of-type {
    margin-left: auto;
    margin-right: 0;
}

.popup__bg .popup__content.filter__popup .all__categories .categories {
    padding-left: 14px;
}

.popup__bg .popup__content.filter__popup .all__categories .categories input {
    display: none;
}

.popup__bg .popup__content.filter__popup .all__categories .categories .category__type {
    border-radius: 8px;
    background: #f4f4f6;
    height: 33px;
    padding: 8px 8px 8px 30px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.popup__bg .popup__content.filter__popup .all__categories .categories .category__type img {
    position: absolute;
    width: 39px;
    left: -14px;
    top: -2px;
}

.popup__bg .popup__content.filter__popup .all__categories .categories .category__type span {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #2a2a2a;
    font-weight: 500;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.popup__bg .popup__content.filter__popup .all__categories .categories input:checked+.category__type {
    background: #f7da1a3a;
}

@media screen and (max-width: 394px) {
    .popup__bg .popup__content.filter__popup .all__categories .categories {
        padding-left: 0px;
    }

    .popup__bg .popup__content.filter__popup .all__categories .categories .category {
        width: 100%;
        -webkit-box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.11);
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.11);
    }

    .popup__bg .popup__content.filter__popup .all__categories .categories .category:nth-of-type(2) {
        margin: 0px 8px;
    }

    .popup__bg .popup__content.filter__popup .all__categories .categories .category__type {
        width: 100%;
        text-align: center;
        border-radius: 0px;
        padding: 8px;
    }

    .popup__bg .popup__content.filter__popup .all__categories .categories .category__type img {
        display: none;
    }
}

.popup__bg .popup__content.filter__popup .sort__by {
    margin-top: 35px;
}

.popup__bg .popup__content.filter__popup .sort__by .sort__options .sort__option label {
    display: block;
    width: 100%;
    padding: 12px 20px;
    text-align: left;
    border-top: 1px solid #eeeeee;
}

.popup__bg .popup__content.filter__popup .sort__by .sort__options .sort__option label span {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.2px;
    font-weight: 400;
    display: block;
    color: #2a2a2a;
    margin-right: auto;
    margin-left: 0;
}

.popup__bg .popup__content.filter__popup .sort__by .sort__options .sort__option label img {
    display: block;
    margin-left: auto;
    margin-right: 0;
    display: none;
}

.popup__bg .popup__content.filter__popup .sort__by .sort__options .sort__option input {
    display: none;
}

.popup__bg .popup__content.filter__popup .sort__by .sort__options .sort__option:last-of-type label {
    border-bottom: 1px solid #eeeeee;
}

.popup__bg .popup__content.filter__popup .sort__by .sort__options .sort__option input:checked+label span {
    color: #d91a31;
    font-weight: 500;
}

.popup__bg .popup__content.filter__popup .sort__by .sort__options .sort__option input:checked+label img {
    display: block;
}

.popup__bg .popup__content.filter__popup .apply__filter {
    padding: 0px 20px;
    margin-top: 25px;
}

.popup__bg .popup__content.filter__popup .apply__filter input[type="submit"] {
    background: #d91a31;
    color: #fff;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.29px;
    border: 1px solid #d91a31;
    border-radius: 8px;
    padding: 15px;
    display: block;
    width: 100%;
}

.popup__bg .popup__content.deal__popup {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.popup__bg .popup__content.qrcode__popup {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 65px 25px 32px 25px;
    text-align: center;
}

.popup__bg .popup__content.qrcode__popup h2,
.popup__bg .popup__content.qrcode__popup h3,
.popup__bg .popup__content.qrcode__popup p {
    margin: 0;
    color: #2a2a2a;
}

.popup__bg .popup__content.qrcode__popup h2 {
    font-size: 27px;
    font-weight: bold;
    line-height: 35px;
}

.popup__bg .popup__content.qrcode__popup h3 {
    font-size: 17px;
    font-weight: bold;
    line-height: 22px;
}

.popup__bg .popup__content.qrcode__popup p {
    font-size: 20px;
    line-height: 26px;
}

.popup__bg .popup__content.qrcode__popup span {
    font-size: 12px;
    line-height: 16px;
    color: #a6a6a6;
}

.popup__bg .popup__content.qrcode__popup .qr-code-image {
    width: 100%;
    margin: 0 auto;
}

.popup__bg .popup__content.qrcode__popup .qr-code-image img {
    width: 100%;
}

.popup__bg .popup__content.add__item {
    padding: 16px 0px 20px 0px;
    position: fixed;
    bottom: 8vh;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 10px 10px 10px 10px;
}

.popup__bg .popup__content.add__item h2,
.popup__bg .popup__content.add__item span,
.popup__bg .popup__content.add__item p {
    margin: 0;
}

.popup__bg .popup__content.add__item .added__item__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0px 25px;
}

.popup__bg .popup__content.add__item .added__item__info .including__stuff {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.popup__bg .popup__content.add__item .added__item__info .including__stuff.inside__image {
    position: absolute;
    bottom: 6px;
    right: 6px;
}

.popup__bg .popup__content.add__item .added__item__info .including__stuff li {
    margin-right: 3px;
}

.popup__bg .popup__content.add__item .added__item__info .including__stuff li:last-of-type {
    margin-right: 0px;
}

.popup__bg .popup__content.add__item .added__item__info .added__item__details {
    padding-top: 4px;
    width: 100%;
}

.popup__bg .popup__content.add__item .added__item__info .added__item__details .added__item__name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup__bg .popup__content.add__item .added__item__info .added__item__details h2 {
    font-size: 19px;
    line-height: 25px;
    font-weight: bold;
    margin-bottom: 3px;
    padding-right: 10px;
}

.popup__bg .popup__content.add__item .added__item__info .added__item__details span {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: #c63939;
}

.popup__bg .popup__content.add__item .added__item__info .added__item__details span span {
    font-size: 18px;
    line-height: 24px;
    color: #888888;
}

.popup__bg .popup__content.add__item .added__item__info .added__item__details p {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2pt;
    color: #888888;
    margin-top: 10px;
    display: block;
    max-width: 90%;
}

.popup__bg .popup__content.add__item .added__item__info .added__item__image {
    position: relative;
}

.popup__bg .popup__content.add__item .added__item__info .added__item__image .zoom__image {
    width: 20px;
    height: 20px;
    background: #d91a31;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 38px;
    right: -6px;
    cursor: pointer;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown .pick {
    font-size: 11px;
    line-height: 14px;
    font-weight: 300;
    margin-left: 1em;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown .dropdown__head {
    background: #909090;
    color: #fff;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown .dropdown__head span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown .dropdown__head svg path {
    fill: #fff;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown .dropdown__head.add__on {
    background: #d5d5d5;
    color: #2a2a2a;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown .dropdown__head.add__on .pick {
    color: #909090;
    font-weight: 300;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown .dropdown__head.add__on svg path {
    fill: #2a2a2a;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown.active .dropdown__head {
    background: #909090;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown.active .dropdown__head.add__on {
    background: #d5d5d5;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown .dropdown__body {
    padding: 14px 21px 20px 21px;
    display: none;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown .dropdown__body.add__on {
    padding: 0px;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown .dropdown__body.add__on .all__checkbox ul {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown .dropdown__body.add__on .all__checkbox ul .each__checkbox label span {
    color: #555555;
    left: 28px;
}

.popup__bg .popup__content.add__item .order__dropdown.add__item__dropdown.success .dropdown__head {
    background: #43ac33;
    border-bottom: 1px solid #fff;
}

.popup__bg .popup__content.add__item .order__dropdown.active .dropdown__body {
    display: block;
}

.popup__bg .popup__content.add__item .sub__dropdown {
    padding: 0px 7px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    background: #fff;
    display: block;
    margin-bottom: 10px;
}

.popup__bg .popup__content.add__item .sub__dropdown:last-of-type {
    margin-bottom: 0px;
}

.popup__bg .popup__content.add__item .sub__dropdown .sub__dropdown__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 11px 0px;
}

.popup__bg .popup__content.add__item .sub__dropdown .pick {
    color: #d91a31;
    padding-bottom: 2px;
    font-weight: 300;
}

.popup__bg .popup__content.add__item .sub__dropdown .sub__dropdown__body {
    display: none;
}

.popup__bg .popup__content.add__item .sub__dropdown .sub__dropdown__body .all__checkbox {
    margin: 0;
    padding: 11px 0px 4px 0px;
    border-top: 1px solid #e1e1e1;
}

.popup__bg .popup__content.add__item .sub__dropdown .sub__dropdown__body .all__checkbox ul {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.popup__bg .popup__content.add__item .sub__dropdown .sub__dropdown__body .all__checkbox ul .each__checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup__bg .popup__content.add__item .sub__dropdown .sub__dropdown__body .all__checkbox ul .each__checkbox span {
    font-size: 13px;
    line-height: 17px;
    color: #555555;
    color: #909090;
}

.popup__bg .popup__content.add__item .sub__dropdown .sub__dropdown__body .all__checkbox ul .each__checkbox label span {
    color: #555555;
    left: 26px;
}

.popup__bg .popup__content.add__item .sub__dropdown .sub__dropdown__body .all__checkbox ul .each__checkbox .extra__price {
    margin-left: auto;
    margin-right: 0px;
}

.popup__bg .popup__content.add__item .sub__dropdown.active .sub__dropdown__head span .pick {
    padding-top: 1px;
    font-weight: 300;
}

.popup__bg .popup__content.add__item .sub__dropdown.active .sub__dropdown__head svg {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.popup__bg .popup__content.add__item .sub__dropdown.active .sub__dropdown__body {
    display: block;
}

.popup__bg .popup__content.add__item .sub__dropdown.success .sub__dropdown__head span .pick {
    color: #43ac33;
    padding-top: 1px;
}

.popup__bg .popup__content.add__item .sub__dropdown.success .sub__dropdown__head svg path {
    fill: #43ac33;
}

.popup__bg .popup__content.add__item .sub__dropdown.for__sides .sub__dropdown__body .all__checkbox .each__checkbox {
    position: relative;
}

.popup__bg .popup__content.add__item .sub__dropdown.for__sides .sub__dropdown__body .all__checkbox .each__checkbox .added_item__count.small {
    margin-left: 170px;
    display: none;
}

.popup__bg .popup__content.add__item .sub__dropdown.for__sides .sub__dropdown__body .all__checkbox .each__checkbox input:checked+label+.added_item__count {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.popup__bg .popup__content.add__item .seprating-head__and__body {
    display: block;
    width: calc(100% - 25px);
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 10px;
}

.popup__bg .popup__content .select__type {
    padding: 0px 15px;
}

.popup__bg .popup__content .select__type .all__checkbox {
    padding: 0;
    margin: 12px 0px 4px 0px;
}

.popup__bg .popup__content .select__type .all__checkbox ul {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.popup__bg .popup__content .select__type h4 {
    font-size: 13px;
    line-height: 17px;
    font-weight: bold;
    color: #555555;
    margin: 0;
}

.popup__bg .popup__content .select__type h4 span {
    font-size: 11px;
    line-height: 14px;
    color: #d91a31;
    font-weight: 300;
}

.popup__bg .popup__content .select__type.success h4 span {
    color: #43ac33;
}

.popup__bg .popup__content .optional__item .sub__dropdown {
    width: calc(100% - 25px);
    margin: 0 auto;
    margin-bottom: 10px;
}

.popup__bg .popup__content .optional__item .sub__dropdown .sub__dropdown__head span {
    color: #555555;
}

.popup__bg .popup__content .optional__item .sub__dropdown .sub__dropdown__head span .pick {
    color: #909090;
    font-size: 11px;
    line-height: 14px;
    font-weight: 300;
}

.popup__bg .popup__content .optional__item .sub__dropdown .sub__dropdown__head svg path {
    fill: #555555;
}

.popup__bg .popup__content .optional__item .sub__dropdown:last-of-type {
    margin-bottom: 0px;
}

.popup__bg.show__filter__popup {
    display: none;
}

.popup__bg.active {
    display: block;
}

/*-----------------------------------------------
   Cart -> Total Payment & Item Count
------------------------------------------------*/
.total-payment__item-count {
    padding: 19px 25px 19px 25px;
}

.total-payment__item-count .row,
.total-payment__item-count .column,
.total-payment__item-count h3,
.total-payment__item-count p {
    margin: 0;
    padding: 0;
}

.total-payment__item-count .total-payment h3 {
    font-size: 19px;
    line-height: 25px;
    font-weight: bold;
    color: #2a2a2a;
}

.added_item__count {
    margin-left: auto;
    margin-right: 0;
    width: 80px;
    padding: 5px 0px 6px 0px;
    border-radius: 6px;
    border: 1px solid #cccccc;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.added_item__count span {
    color: #555555;
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    display: block;
}

.added_item__count span:nth-of-type(2) {
    width: 40px;
    text-align: center;
}

.added_item__count.small {
    width: 60px;
    padding: 3px 6px 4px 6px;
}

.added_item__count.small span {
    font-size: 13px;
}

.added_item__count.small span:nth-of-type(2) {
    display: block;
    text-align: center;
}

/*-----------------------------------------------
   Cart -> Add to cart button
------------------------------------------------*/
.add__to__cart {
    background: #bf1b2f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: calc(100% - 50px);
    height: 49px;
    margin: 0 auto;
    border-radius: 8px;
    color: #fff;
    padding: 0px 16px;
    z-index: 9;
}

.add__to__cart.disable {
    opacity: 50%;
}

.add__to__cart span {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.29px;
}

.add__to__cart .total__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.add__to__cart .total__items span {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.add__to__cart .items__price {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: 0px;
    cursor: pointer;
}

/*-----------------------------------------------
   Search Items Pop Up
------------------------------------------------*/
.search__items__popup-body {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    overflow-y: scroll;
    display: none;
    /* Search pop up main area */
}

.search__items__popup-body.active {
    display: block;
}

.search__items__popup-body .search__items__popup__content {
    /* Search pop up header */
    padding-top: 70px;
    /* Searched History */
    /* Searched Items */
}

.search__items__popup-body .search__items__popup__content .search__navbar {
    position: fixed;
    width: 100%;
    padding: 35px 16px 10px 16px;
    background: #fff;
    z-index: 9999;
    top: 0;
    left: 0;
}

.search__items__popup-body .search__items__popup__content .search__navbar .go-back__search-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.search__items__popup-body .search__items__popup__content .search__navbar .go-back__search-form .go__back {
    min-width: 38px;
    text-align: center;
    margin-right: 5px;
}

.search__items__popup-body .search__items__popup__content .search__navbar .go-back__search-form .search__form {
    width: 100%;
}

.search__items__popup-body .search__items__popup__content .search__navbar .go-back__search-form .search__form .input__group {
    position: relative;
}

.search__items__popup-body .search__items__popup__content .search__navbar .go-back__search-form .search__form .input__group input {
    border: none;
    outline: none;
    width: 100%;
    height: 41px;
    border-radius: 6px;
    background: #f6f6f6;
    padding-left: 35px;
    font-size: 14px;
    line-height: #555;
    font-family: "DM Sans";
}

.search__items__popup-body .search__items__popup__content .search__navbar .go-back__search-form .search__form .input__group input::-webkit-input-placeholder {
    color: #909090;
}

.search__items__popup-body .search__items__popup__content .search__navbar .go-back__search-form .search__form .input__group input:-ms-input-placeholder {
    color: #909090;
}

.search__items__popup-body .search__items__popup__content .search__navbar .go-back__search-form .search__form .input__group input::-ms-input-placeholder {
    color: #909090;
}

.search__items__popup-body .search__items__popup__content .search__navbar .go-back__search-form .search__form .input__group input::placeholder {
    color: #909090;
}

.search__items__popup-body .search__items__popup__content .search__navbar .go-back__search-form .search__form .input__group svg {
    display: block;
    position: absolute;
    left: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.search__items__popup-body .search__items__popup__content .search__history {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px 28px;
    margin-top: 27px;
}

.search__items__popup-body .search__items__popup__content .search__history .search__result,
.search__items__popup-body .search__items__popup__content .search__history .clear__all {
    width: 100%;
}

.search__items__popup-body .search__items__popup__content .search__history .search__result span,
.search__items__popup-body .search__items__popup__content .search__history .clear__all span {
    font-size: 12px;
    font-weight: 16px;
    letter-spacing: 0.55px;
    text-transform: uppercase;
    color: #bdbdbd;
}

.search__items__popup-body .search__items__popup__content .search__history .clear__all {
    text-align: right;
}

.search__items__popup-body .search__items__popup__content .search__history .clear__all span {
    font-weight: 600;
    color: #d91a31;
}

.search__items__popup-body .search__items__popup__content .all_items {
    padding: 0px 16px 0px 16px;
}

.search__items__popup-body .search__items__popup__content .all_items .column {
    margin-bottom: 15px;
}

.search__items__popup-body .search__items__popup__content .all_items .item__card {
    -webkit-box-shadow: 1px 3px 19px #0000001f;
    box-shadow: 1px 3px 19px #0000001f;
}

/*-----------------------------------------------
    eDine by LevelFive
------------------------------------------------*/
.edine-by-levelfive {
    position: fixed;
    bottom: 0 !important;
    left: 0;
    background: #fff;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 9px 0px;
}

.edine-by-levelfive span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: "Roboto";
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.47px;
    color: #747474;
}

.edine-by-levelfive span img {
    margin-right: 7px;
}

/*-----------------------------------------------
    OTP Verification
------------------------------------------------*/
.otp__verification {
    text-align: center;
    padding: 0px 40px;
    margin-top: 25%;
}

.otp__verification img {
    max-width: 176px;
    margin-bottom: 11px;
}

.otp__verification h1,
.otp__verification p {
    margin: 0;
    color: #2a2a2a;
}

.otp__verification h1 {
    font-weight: bold;
    font-size: 27px;
    line-height: 35px;
    margin-bottom: 9px;
}

.otp__verification p {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 40px;
}

.otp__verification form input {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.17px;
    border: none;
    outline: none;
    background: #ececec;
    color: #2a2a2a;
    height: 52px;
    border-radius: 8px;
}

.otp__verification form input[type="submit"] {
    background: #909090;
    color: #fff;
    font-weight: 500;
}

.otp__verification form .otp__group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 40px;
}

.otp__verification form .otp__group input {
    font-size: 37px;
    font-weight: 500;
    line-height: 48px;
    height: 83px;
    margin-right: 10px;
    border-radius: 9px;
}

.otp__verification form .otp__group input:focus {
    border: 1px solid #909090;
}

.otp__verification form .otp__group input:last-of-type {
    margin-right: 0px;
}

.checkout_items {
    /* width: 380px;*/
    /*  top: 617px;*/
    /*  margin: 0 auto;
  left: 0;
  right: 0;*/
}

/*-----------------------------------------------
// This CSS is responsible for creating a frame on desktop
// Means creating a mobile view on desktop 
------------------------------------------------*/
@media screen and (min-width: 300px) {
    .body {
        position: relative;
        background: #efefef;
        max-width: 412px;
        /*    min-height: 93vh;*/
        min-height: calc(var(--vh, 1vh) * 100);
        max-height: 850px;
        overflow: hidden;
        margin: 0 auto;
        -webkit-box-shadow: 0 0 10px #2727271f;
        box-shadow: 0 0 10px #2727271f;
        overflow-y: auto;
    }

    .body-extend {
        min-height: calc(100vh - 230px);
    }

    .fixed-area {
        max-width: 412px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .main {
        margin-top: 248px;
        /* max-height: calc(850px - 248px);*/
        overflow: hidden;
        overflow-y: auto;
    }

    .main.dine-in {
        margin-top: 250px !important;
        /*    max-height: calc(850px - 250px);*/
    }

    .popup__bg {
        max-width: 412px;
        max-height: 850px;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .popup__bg .popup__content {
        position: absolute !important;
    }

    .popup__bg .popup__content.__date-time,
    .popup__bg .popup__content.qrcode__popup,
    .popup__bg .popup__content.deal__popup {
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .search__items__popup-body {
        max-width: 412px;
        max-height: 95vh;
        margin: 0 auto;
        overflow: hidden;
        left: 0;
        right: 0;
    }

    .search__items__popup-body .search__items__popup__content {
        padding-top: 12px;
        margin-top: 86px;
        height: calc(100vh - 126px);
        overflow: auto;
    }

    .search__items__popup-body .search__items__popup__content .search__history {
        margin-top: 0px;
    }

    .search__items__popup-body .search__navbar {
        max-width: 412px;
        margin: 0 auto;
        left: 0;
        right: 0;
        overflow: hidden;
    }

    .hungary__for__more .more__items,
    .menu__cards {
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
    }

    .hungary__for__more .more__items::-webkit-scrollbar,
    .menu__cards::-webkit-scrollbar {
        display: none;
    }

    .checkout_items {
        /*    width: calc(412px - 32px);
     47 from bottom & 60 height 
    // top: calc(850px - 180px);
    margin: 0 auto;
    left: 0;
    right: 0;*/
    }

    .edine-by-levelfive {
        // top: calc(850px - 41px);
    }

    .edine-by-levelfive,
    .success__notification {
        max-width: 412px;
        margin: 0 auto;
        left: 0;
        right: 0;
        z-index: 100;
    }

    .navbar.navbar-2.for__cart {
        max-width: 412px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .side__menu {
        height: calc(850px - 59px);
        position: absolute;
    }

    .bottom__area {
        position: absolute;
    }

    .cart.cart-1 {
        margin-top: 59px !important;
        padding-top: 0px;
        height: 321px;
        overflow: hidden;
        overflow-y: auto;
    }

    .cart.cart-1 .cart__items {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .cart.cart-3 {
        padding-top: 0px !important;
        margin: 0 !important;
        margin-top: 59px !important;
        height: calc(850px - 118px);
        overflow: hidden;
        overflow-y: auto;
    }

    .continue__browsing {
        position: absolute;
        max-width: 412px;
        height: 59px;
        margin: 0 auto;
        top: calc(850px - 59px);
        left: 0;
        right: 0;
    }

    .search__locations,
    .pickup__locations {
        margin-bottom: 50px !important;
    }
}

.dine-message {
    position: fixed;
    width: 100vw;
    height: max-content;
    padding: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0;
    color: white;
    font-weight: bold;
    z-index: 99999;

    span,
    i {
        color: white;
        padding: 0 2px;
    }

    &.success {
        background-color: #4ac182;
        animation-name: dropDownMessage;
        animation-duration: 3s;
        animation-iteration-count: 1;
    }

    &.error {
        animation-name: dropDownMessage;
        animation-duration: 3s;
        animation-iteration-count: 1;
        background-color: #dd2323;
    }
}

.hero-banner {
    overflow-y: auto;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger>.p-button,
.p-splitbutton.p-button-danger>.p-button {
    border: none;
}


.fade-in-text {
    font-family: Arial;
    font-size: 60px;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.increase__count,
.reduce__count {
    cursor: pointer;
}
